@mixin extraLarge{
    @media only screen and (min-width:1200px){
        @content;
    }
}

@mixin laptop{
    @media only screen and (max-width:1199px){
        @content;
    }
}

@mixin laptopMin{
    @media only screen and (min-width:992px){
        @content;
    }
}


@mixin large{
    @media only screen and (max-width:991px){
        @content;
    }
}

@mixin largeMin{
    @media only screen and (min-width:768px){
        @content;
    }
}


@mixin medium{
    @media only screen and (max-width:767px){
        @content;
    }
}

@mixin small{
    @media only screen and (max-width:575px){
        @content;
    }
}
@mixin extraSmall{
    @media only screen and (max-width:480px){
        @content;
    }
}
