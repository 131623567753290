.highlight_icons{
    padding:45px 0;
    border-bottom:1px solid #ddab20;
    .single_icon{
        text-align: center;

        @include laptop{
            margin-bottom: 15px;
        }
    }

    i{
        font-size: 45px;
        position: relative;
        top: 8px;
        color:$lightRed;
        margin-right: 15px;

        @include medium{
            display: block;
            margin-bottom: 20px;
        }
    }
    span{
        font-size: 14px;
        margin-left: 8px;
    }

    @include small{
    	display: none;
    }
}



.highlighted_products{
    padding:45px 0;
    .row{
        height: 100%;

        @include medium{
            .col-md-6, .col-md-12{
                margin-bottom: 25px;
            }
        }
    }

    .single_product{
        position: relative;
        min-height: 260px;
        height: 100%;
        .product_promotion{
            background: #ddd;
            text-align: center;
            padding:10px 0;
            color:$lightRed;
        }

        .image_holder{
            // background: url('../img/home_sp_1.jpg');
            position: absolute;
            width: 100%;
            height: 100%;
            background-size:cover;
            z-index: -1;
            left: 0;
            top: 0;
            
        }
        .text_holder{
            padding-top: 15px;
            position: absolute;
            bottom: 0px;
            text-align: center;
            width: 100%;
            padding: 15px;
            
            h2{

            }

        

            
        }

        a{
            &:hover{
                text-decoration: none;
                
                
            }
        }

        &.with_text{
            // background:rgba(237,237,237,.5);
        
            // background:rgba(0,0,0,.3);
            color:$white;
            h3{
                text-align: left;
            }
            ul{
                text-align: left; 
                color:$white;
                list-style-type: square;
                padding-left: 15px;
            }
        }
    }
}


.popular_products{
    padding-bottom: 45px;
    .area_title{
    }

    .single_product{
        margin-bottom: 25px;
        a{
            text-decoration: none;

            &:hover{
                text-decoration: none;
                color:$mainYellow;
            }
        }
        .image_area{
            position: relative;
            min-height: 260px;
            .image_holder{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                background: url('../img/home_sp_1.jpg');
                background-size: cover;
                z-index: -1;
            }
        }

        .info{
            background: $lightGray;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 8px;
            transition:all .3s ease;
            text-align: center;
            .name{
                margin-bottom: 0px;
                font-family: 'Montserrat-Light';
            }

            .price{
                margin-bottom: 0px;
                font-size: 14px;
                
            }

            
        }

        a:hover{
            .info{
                background: #000;
                color:$lightGray;
            }
        }
        
    }
}

.signup_section{
    background: #39414B;
    padding:45px 0;
    color:#fff;

    p{
        margin-bottom: 0;
    }

    input[type="email"]{
        height: 40px;
        padding-left: 15px;
        border-radius: 3px;
        border: none;

        @include medium{
            width: 70%;
        }

        @include extraSmall{
            width: 100%;
            margin-bottom: 15px;
        }
    }

    input[type="text"]{
        height: 40px;
        padding-left: 15px;
        border-radius: 3px;
        border: none;

        @include medium{
            width: 70%;
        }

        @include extraSmall{
            width: 100%;
            margin-bottom: 15px;
        }
    }

    input[type="submit"]{
        height: 40px;
        padding-left: 19px;
        padding-right: 20px;
        position: relative;
        left: -5px;
        border: none;
        background:$lightRed;
        transition:.3s ease;

        &:hover{
            background: #000;
            color:$mainGreen;
        }
        @include extraSmall{
            width: 100%;
            left: 0;
            border-radius: 3px;
        }
    }

    @include medium{
        div.col-md-6{
            text-align: center;
            &:first-child{
                margin-bottom: 20px !important;
            }
        }
    }
}

.print_professionals{
    padding:45px 0;
    text-align: center;

    .area_title{
        margin-bottom: 30px;
    }

    .single_item{
        text-align: center;
        img{
            max-width: 30px;
        }
        .title{
            font-family: 'Montserrat-Light';
            font-size: 18px;
            margin-bottom: 15px;
            margin-top: 8px;
        }

        p{
            font-family: 'Montserrat-Light';
            font-size: 15px; 
        }
    }
}


.blog_area{

    padding:45px 0;
    padding-top: 0;
    .area_title{
        margin-bottom: 25px;
    }

    .single_post{

        .image_area{
            min-height: 260px;
            background: url('../img/custom-t-shirt-printing.jpg');
            background-size:cover;
        }

        .post_title{
            font-family: 'Montserrat-Light';
            font-size: 18px;
            margin-top: 8px;
        }

        a:hover{

            color:#000;

        }


    }
}

.footer_top{
    background: $darkGray;
    padding:45px 0;
    color:#fff;

    .column_heading{
        // font-family: 'Montserrat-Light';
        font-size: 16px;
        text-transform: uppercase;
        color:$mainYellow;
    }

    ul{
    	padding:0;
    	list-style-type: none;

        li{
            padding:4px 0;

            a{
                font-size: 15px;
                color:#fff;
                transition:all .3s ease;
                padding-left: 0;
                margin-left: 0;
                padding-top: 0;
                padding-bottom: 0;
                &:hover{
                    color:$mainYellow;
                    margin-left:10px;
                }
            }
        }
    }
}

.copyright_area{
    padding:25px 0;

    p{
        font-family: 'Montserrat-Light';
        font-size: 13px;
        margin-bottom: 0;
    }

    .footer_social{
        float: right;
        margin-bottom: 0;
        li{
            display: inline;
            float: left;
            a{
                display: inline-block;
                padding:0 15px;
                i{
                    font-size: 25px;
                    padding: 0;
                }

                &:hover{
                    i{
                        color:$mainYellow;
                    }
                }
            }
        }

        @include small{
        	padding: 0;
        	li{
        		a{
        			padding-left: 0;
        		}
        	}
        }
    }

    @include small{
        .col-sm-6{
            text-align: left;
            ul{
                float: left !important;
                margin-top: 15px;
            }
        }
    }
}