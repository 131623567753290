$mainYellow : #ddab20;

$darkGray: #39414B;

$mainGreen: #95C43E;

$lightGray: #EFEDEE;

$lightRed: #f95b04;

$white: #fff;

$navyBlue: #184156;

$darkBlack : #3A414C;