.single_product_area{
    padding:50px 0;

    .info_before_product_image{
        svg{
            position: relative !important;
            max-width: 100px;
        }
    }

    .product_image{

        .slides{
            li{
                p{
                    background: #dddddd;
                    padding:8px;
                    margin-bottom: 0;
                }
            }
        }
        
        .flexslider{
            margin-bottom: 0;
            border-top: 0px solid #000;
        }
    }

    .product_description{
        font-size: 14px;
        font-family: 'Montserrat-Light';
        margin: 15px 0;
    }

    .accordion_area{

        .card{
            border:none;
            border-bottom:1px solid #000;
            border-top: 1px solid #000;
            &:last-child{
                border-top: 0px solid #000;
            }
        }


        .card-header{
            background: none;
            border-radius: 0;
            border-bottom: 0;
            padding-left: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            
            .btn{
                font-size: 13px;
                color:#000;
            }

            button{
                padding-left: 0;
                width: 100%;
                text-align: left;
            }

            i{
                font-size: 25px;
                position: relative;
                transition:all .5s ease;
                transform:rotate(180deg);
                float: right;
            }

            .collapsed {
                i{
                    transform:rotate(0deg) !important;
                }
            }

            .btn-link:hover{
                text-decoration: none;
            }
        }

        .card-body{
            padding-left: 0;
            padding-top: 0;
        }

        .artwork_templates{
            overflow: hidden;
            li{
                display:inline;
                float: left;

                a{
                    position: relative;
                    text-indent: -9999px;
                    width:36px;
                    height: 36px;
                    display: block;
                
                    &:after{
                        
                        background-repeat:no-repeat;
                        position: absolute;
                        content:'';
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        transition:opacity .5s ease;
                        opacity:0;
                        

                    }

                    &:hover{
                        &:after{
                            opacity:1;
                            z-index: 9;
                        }
                    }
                }

                &.photoshop{
                    a{
                        background-position: 0px -36px !important;
                        background:url('../img/templates-icons.png');
    
                        &:hover{
                            &:after{
                                background:url('../img/templates-icons.png');
                                background-position: 0px 0px;
                            }
                        }
                    }
                }

                &.illustrator{
                    a{
                        background-position: -36px -36px !important;
                        background:url('../img/templates-icons.png');
    
                        &:hover{
                            &:after{
                                background:url('../img/templates-icons.png');
                                background-position: -36px 0px;
                            }
                        }
                    }
                }

                &.id{
                    a{
                        background-position: -72px -36px !important;
                        background:url('../img/templates-icons.png');
    
                        &:hover{
                            &:after{
                                background:url('../img/templates-icons.png');
                                background-position: -72px 0px;
                            }
                        }
                    }
                }

                &.pdf{
                    a{
                        background-position: -108px -36px !important;
                        background:url('../img/templates-icons.png');
    
                        &:hover{
                            &:after{
                                background:url('../img/templates-icons.png');
                                background-position: -108px 0px;
                            }
                        }
                    }
                }
            }
        }
    }

}


.single_product_speciality_area{
    padding:75px 0;
    .single_special{
        text-align: center;

        i{
            color:$lightRed;
            font-size: 35px;
        }

        .title{
            font-size: 20px;
            margin-top: 15px;
        }
        p{
            font-size: 16px;
        }
    }
}



.single_product_tabbed_area{
	margin-bottom:70px;
    .nav-link.active{
        background: #EFEDEE;
        border-bottom:none;
    }
    .nav-tabs .nav-item{
        margin-bottom: -2px;

        @include small{
        	width: 45%;
        	margin-right: 5%;
        	border-bottom:1px solid #000;
        	margin-bottom: 10px;
        }
    }

    .nav-tabs .nav-link{
    	@include medium{
    		font-size: 14px;
    	}

    	@include small {
    		font-size: 12px;
    	}
    	@include extraSmall {
    		font-size: 10px;
    		padding-left: 1px;
    		padding-right: 1px;
    	}
    }

    .tab-content{
        background: #EFEDEE;
        padding:25px;
        font-family: 'Montserrat-Light';

        h2{
            font-family: 'Montserrat-Light';
        }

        p{

        }

        ul{
            padding-left: 30px;
            /*list-style-type: square;*/
            li{

            }
        }
		
		ul.descriptions_block{
			padding-bottom: 30px;
			li{
				margin-bottom: 10px;
				.heading{
					text-align: right;
					font-weight: bold;

					@include medium{
						text-align: left;
					}

				}

				.text{
					font-size: 13px;
				}
			}

			ul{
				padding-left: 0;
				li{
					margin-bottom: 5px;
				}
			}
		}

		.instructions{
			border-top: 1px solid #000;
			padding-top: 30px;
			ul{
				padding-left: 15px;
				list-style-type: square;
			}
		}

		.assets{
			.single_asset{
				padding:15px;
				border:1px solid #000;
				border-radius: 3px;
				margin-bottom: 25px;
			}

			.title{
				font-size: 20px;
				font-weight: bold;

				.size{
					display: block;
					margin-top: 5px;
					margin-bottom: 12px;
					font-size: 17px;

				}
			}

			a{
				display: block;
				margin-top: 25px;
				text-decoration:underline !important;
				img{
					max-width: 30px;
				}
				&:hover{
					color:$lightRed;
				}
			}
		}

    }
}

.single-products{
    .breadcrumbs_col{
        .breadcrumbs{
            padding-left: 4px;
        }

        i{
            font-size: 12px;
        }
        
    }

    .title_col{
        h1{
            margin:0;
            font-weight: bold;
        }
    }
    .heading_row{
        margin-bottom: 15px;
    }
}


