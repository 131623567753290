.logo_bar{
    padding: 20px 0;
    background: #ddd;
    .logo{

        img{
            max-width: 300px;
        }

        @include medium{
            display: block;
            text-align: center;
        }
    }

    .search_phone{
        float: right;

        .search_holder{
            position: relative;
            i{
                position: absolute;
                left: 11px;
                top: 0px;
                font-size: 18px;
            }

            input{
                height: 40px;
                padding-left: 35px;
            }
        }

        a.phone{
            display: inline-block;
            position: relative;
            top: 8px;
            margin-left: 20px;
            text-align: center;
            &:active{
            	color:$darkBlack;
            }
            &:hover{
            	color:$darkBlack;
            }

            span{
                display: block;
            }
        }

        @include medium{
            float: none;
            text-align: left;
            
        }

        @include extraSmall{
            .search_holder{
                display: none;
            }
        }
    }
}


/*=============== New Menu work ===================*/


.menu_area{
	background:$mainGreen;
}

ul li a {
      color: #292929;
      text-decoration: none;
      padding: 15px;
      display: inline-block;
      width: 100%;
}



nav ul ul {
      position: absolute;
      display: none;
}

ul ul li {
      display: block;
}

ul li:hover ul {
      display: block;
}

ul li i {
      color: #292929;
      float: right;
      padding-left: 20px;
}

.menu_toggler{
      display: none;
}

@media(max-width: 768px) {
      .menu_toggler {
           display: block;
      }

      ul.main_menu {
            display: none;
            position: static;
            background: #e3e3e3;
            max-width: 70%;


      }

      ul li {
            display: block;
      }

      ul ul {
            position: static;
            background: #e3e3e3;

      }

      ul li:hover ul {
	      display: none;
	}

      
}


	div.menu_toggler{
	    position: absolute;
	    right: 0;
	    z-index: 99;
	    bottom: 5px;
	    background: none;
	    font-size: 25px;
	    cursor:pointer;

	    @include small{
	    	right: 25px;
	    }


	}

ul.main_menu {
	margin: 0;
	padding: 0;
	list-style-type: none;
	
	position: relative;

	& > li{
		display: inline-block;
		&:hover{
			 background: lightgrey;
		}

		& > a{
			position: relative;
			i{
				position: absolute;
			    right: 0;
			    top: 19px;
			    font-weight: bold;
			    font-size: 20px;
			}
		}

		/* Submenu item */
		& > ul{ 
			width: 100%;
			left: 0;
			z-index: 1;
			height: 270px;
			background:$darkBlack;
			padding: 15px;
			& > li{
				display: inline-block;
				width:25%;
				float: left;
				position: relative;
				padding-right:15px;

				& > a{
					color:$lightRed;
					font-weight: bold;
					border-bottom:1px solid $mainGreen;
					width: 100%;
					padding: 0;
					
				}

				& > ul{
					width: 100%;
					padding-left: 0px;
					& > li{
						display: block;
						a{
							padding-left: 0;
							padding-top: 5px;
							padding-bottom: 5px;
							color:$white;

							&:hover{
								color:$lightRed;
							}
						}
					}
				}
			}
		}
	}
}

@include medium{

	ul.main_menu {
		background: #fff;
		width: 50%;

		& > li{
			display: block;
			width: 100%;
			border-bottom:1px solid #000;

			&:last-child{
				border-bottom:0px;
			}
			

			&:hover{
				background:none;
			}

			a{
				
				i{
					position: absolute;
				    right: 50px;
				    top: 19px;
				    font-weight: bold;
				    font-size: 20px;
				}
			}

			/* Submenu item */
			& > ul{
				padding-left: 25px;
				background:none;
				position: relative;
				overflow: hidden;
				height: auto;

				& > li{
					width: 100%;
					font-size: 13px;

					a{
						font-size: 13px;
						display: inline-block;

					}

					& > ul{
						background:none;
						position: relative;
						
						& > li{
							font-size: 13px;
							a{
								font-size: 13px;
								color:$darkBlack;

								&:hover{
									
								}
							}
						}
					}

					&:last-child{
						/*border-bottom: 3px solid $mainGreen;*/
					}
				}
			}
		}
	}

	nav{
		position: absolute;
		z-index: 9;
		background:rgba(255,255,255,.5);
		width: 100%;
	}
}


@include small{

ul.main_menu {


		& > li{

			&:hover{

			}

			/* Submenu item */
			& > ul{
				& > li{

					& > a{
						
					}

					& > ul{
						
						& > li{
							a{
								&:hover{
									
								}
							}
						}
					}

					&:last-child{
						/*border-bottom: 3px solid $mainGreen;*/
					}
				}
			}
		}
	}


}